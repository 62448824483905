import React from "react";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Accordion from "../components/Reusables/Accordion";
import Layout from "../components/layout";
import styled from "styled-components";
import { graphql } from "gatsby";
import axios from "axios";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
const StyledWrapper = styled.div`
  .accordion-start {
    padding-top: 50px;
  }

  .slaughter-img {
    width: 300px;
  }
`;

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Argent />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/ardent`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};

function Argent() {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const { data: pageData } = useQuery("argent", fetchData);
  const { data: heroImage } = useQuery("argentImages", fetchHeroImage);
  const mainUrl = process.env.GATSBY_API_URL;

  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
  }, [pageData, heroImage]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        {newData && <SEO title={newData.SEOtitle} description={newData.SEOdescription}/>}
        {newData &&
          newData.showHeroImage &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "ArgentPage".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}
        {newData && newData.showSponserImage && (
          <div className="padding-left-8 padding-right-8 accordion-start">
            <h1>Building Success Programme</h1>
            <p>Exclusively sponsored by</p>
            {newData.sponserImage && (
              <img
                className="img-fluid slaughter-img"
                src={mainUrl + newData.sponserImage.url}
                alt="slaughterlogo"
              />
            )}
          </div>
        )}
        {newData && newData.showAccordion && (
          <div className="container-fluid p-0">
            <Accordion
              accordionHeading={newData.AccordionHeading}
              accordion={newData.Accordion}
            />
          </div>
        )}
      </StyledWrapper>
    </Layout>
  );
}
